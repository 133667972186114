import React , {useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';
import Addon from './Addon';

export default function FacturaDetalle({data, removeHandler, indice}) {

useEffect(() => {

    console.log("data add on",  data.order_product_addons );
    
}, [])



    const totalAddons = (next)=>{
       return next.order_product_addons ?   next.order_product_addons.reduce((prevAddon,nextAddon)=>{
            return parseInt(prevAddon) + parseInt(nextAddon.price*next.cantidad);
        },0):
        0;
    }


    return (
        <div>


                <div className="flex   border-gray-800 border-b">


                        <div className="px-4 py-2 bg-white w-full">

                        <div className="flex flex-col">
                            <div className="flex flex-col justify-start items-start">
                                    {   data.payWithMc &&
                                        <div className="bg-green-200 px-2 py-1 rounded-md text-sm uppercase bold">producto pagado con megacoins</div>}

                                    <div className="flex w-full">
                                        
                                        <h3 className="text-gray-900  font-bold  flex-grow mr-5"> {data.cantidad} {data.nameFront} </h3>

                                        <div className="flex flex-col items-end ">
                                                <h3 className="text-gray-900  font-bold w-full text-right "> 
                                                    {  removeHandler &&  <span onClick={()=>removeHandler(data,indice)} 
                                                    className="mr-3 hover:text-red-400 text-red-600"><FontAwesomeIcon icon={['fas','trash']}   /></span> }
                                                <NumberFormat value={data.precio*data.cantidad} displayType={'text'} thousandSeparator={true} prefix={''} />
                                                </h3>

                                                {
                                                    data.modifiablePrice &&  <h4 className="text-gray-800  text-xs text-right "> 
                                                        <NumberFormat value={ totalAddons(data) } displayType={'text'} thousandSeparator={true} prefix={''} />
                                                    </h4>
                                                }
                                        </div>
                                    </div>
                            </div>

                            <div className="text-xs">{data.comments}</div>
                        </div>




<div>
    {JSON.stringify()}
</div>


                        <div className=" text-gray-700 inline-flex mt-1">{ 
                            data.order_product_addons &&  data.order_product_addons.map( (a)=>  <Addon data={a}/>  )   
                            
                            }
                            </div>


                            {data.opciones &&  <div className=" text-gray-700   mt-1">{ 
                              data.opciones.map( (a)=> a.nombre   ).join(', ')   
                            }
                            </div>}






                        </div>

                </div>



        </div>
    )
}
