import React, {useState, useEffect} from 'react'
import DateTimePicker from 'react-datetime-picker';
import Moment from 'react-moment';
import moment from 'moment'
import 'moment/locale/es';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from 'react-number-format';
import TablaPedidos from '../components/TablaPedidos';




import {  useDispatch, useSelector } from 'react-redux';


import {
   setFecha, selectFecha, selectSucursales
} from '../features/masterSlice';
import TablaPedidosIntegracion from "../components/TablaPedidosIntegracion";





const ToggleButton = ({onChange, value, title})=><button type="button" onClick={onChange} className={`focus:outline-none active:outline-none px-2  py-1 ${value?'bg-green-200':'bg-gray-200 line-through'} `}  >
  {title}
</button>




export default function PedidosPage(props) {



    console.log(" ************  ************ PedidosPage RENDERING  ************ ************")

    const [today,setToday]=useState();
  //  const [fecha,setFecha]=useState(null);
    const [res,setRes]=useState(null);
    const [filtered,setFiltered]=useState(null);
    const [loading,setLoading]=useState(false);

    const [onlyCc, setOnlyCc]= useState(true);
    const [onlyApp, setOnlyApp]= useState(true);
    const [onlyDelivery, setOnlyDelivery]= useState(true);
    const [onlyFasttrack, setOnlyFasttrack]= useState(true);
    const [onlyDrivein, setOnlyDrivein]= useState(true);
    const [onlyProgramado, setOnlyProgramado]= useState(true);
    const [onlyPickup, setOnlyPickup]= useState(true);

   

    const formasPago= ['efectivo', 'bancard', 'pos'];
    const [filtroFp, setFiltroFp] = useState( formasPago )
    const [filtroSucursal, setFiltroSucursal] = useState([])



    const sucursales = useSelector(selectSucursales);



    const dispatch = useDispatch();

    const fecha=useSelector(selectFecha);


    useEffect(() => {
      
     if(res) setFiltered( res.data );

    }, [res])


    useEffect(() => {
      
      setFiltroSucursal (  sucursales.map( i => i.nombre) )
 
     }, [sucursales])




    useEffect(() => {
  
     

     if( res ){


      let porCanal= res.data.filter( (i, indice)=>
        
       ( onlyCc && (i.tomadoPor>0) ) || ( onlyApp && ( typeof(i.tomadoPor)==='undefined' ) )  )    
                


       let porModalidad= porCanal.filter( (i, indice)=>
        
           (onlyDelivery && (i.modalidad==='delivery') )   
           || ( onlyProgramado && (i.modalidad==='programado') ) 
           || ( onlyDrivein && (i.modalidad==='drivein') ) 
           || ( onlyFasttrack && (i.modalidad==='fasttrack') )  
           || ( onlyPickup && (i.modalidad==='pickup') ) 
       
       )    


       let z= porModalidad.filter( (i, indice)=>
            filtroFp.includes( i.formaPago ) && filtroSucursal.includes( i.sucursal )
       )    
                



      setFiltered( z );

    }

    }, [onlyCc, onlyApp, onlyDelivery, onlyProgramado, onlyFasttrack, onlyDrivein, onlyPickup,  filtroFp, filtroSucursal])




    useEffect(() => {
      if(fecha) consultarPedidos(fecha);
    }, [fecha])



      useEffect(() => {
       
        moment.locale(['es']);
//        setFecha( moment().toDate() );
        setToday( moment().toDate() );
    }, [props.state])


    const onChange = date => {
       
        dispatch(   setFecha(  moment(date).toString() ) );

        consultarPedidos(date);
    }



    const solicitarRetorno = (id)=>{

      axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/pedidos/solicitarRetorno?id=${id}` ).then(
          (result)=>{
             
             consultarPedidos(fecha);
          }
      );        
  }


  const verPedido= (id)=>{
    props.history.push(`/pedidos/${id}`)
  }


  const regenerar= (id)=>{
    props.history.push(`/callcenter/productos`,{id:id});
  }





    const consultarPedidos = (date)=>{
         


        let d= moment(date).toISOString();
        setLoading(true);
        axios.get(  process.env.REACT_APP_API_ENDPOINT + `delivery/pedidos/head?fecha=${d}` ).then(
            (result)=>{
          
               setRes(result.data);
               setLoading(false);
            }
        ).catch( e=>{
          setLoading(false);
          
        })
        .finally(()=>{
          setLoading(false);
        })
    }






    const integrarPb = (pedidoId) => {

      setLoading(true)
  
  
      // const pin = prompt("Ingrese el pin de integracion")
  
      // if(pin)
        {
  
        const data= {
          pedidoId,
          pin: "19825"
        }
        axios.post(process.env.REACT_APP_API_ENDPOINT + `delivery/pedido/integrarPb`, data).then(
          (result) => {
            console.log("result", result.data)
  
            const {status, message} = result.data
  
            if(status){
              // refreshData();
            }else{
              alert(message)
            }
  
            // alert( "OK: "+ result.data.message.registeredDate);
  
            setLoading(false)
            // props.closeModalHandler()
          }
        ).catch(e => {
          console.log("fail integrarPb error", e)
          alert( "Falla de comunicación" );
          setLoading(false)
        }).finally(()=>{
          setLoading(false)
          consultarPedidos(fecha)

        })
      }
  
    }






    return (


      <div className="w-full  relative   flex  justify-center overflow-scroll  ">

      <div className="container absolute    p-5    ">



    <div className="flex flex-col justify-start bg-teal-500 border rounded   gap-2  p-3">
          <div className="">
            {/* <DateTimePicker
            locale="es"
            
            format="d/MM/y"
            clearIcon={null}
            onChange={onChange}
            value={fecha}
            /> */}

          {!loading ? <span onClick={()=>consultarPedidos(fecha)} 
                          className="ml-3 hover:text-green-800 text-black"><FontAwesomeIcon icon={['fas','redo-alt']}   /></span>:'cargando...'}

          {
              moment(fecha).format('l') !== moment(today).format('l')  ? <FontAwesomeIcon className="ml-3" icon={['fas','exclamation-triangle']}   /> :null

          }


            <div className="inline-flex  rounded gap-2 mx-5">
                <ToggleButton   onChange={ ()=> setOnlyCc((p)=>!p)   }  value={onlyCc}  title="callcenter"  />
                <ToggleButton   onChange={ ()=> setOnlyApp((p)=>!p)   }  value={onlyApp}  title="app"  />
            </div>


            <div className="inline-flex  rounded mr-5 gap-2">
              <ToggleButton   onChange={ ()=> setOnlyDelivery((p)=>!p)   }  value={onlyDelivery}  title="delivery"  />
              <ToggleButton   onChange={ ()=> setOnlyProgramado((p)=>!p)   }  value={onlyProgramado}  title="programado"  />
              <ToggleButton   onChange={ ()=> setOnlyFasttrack((p)=>!p)   }  value={onlyFasttrack}  title="fasttrack"  />
              <ToggleButton   onChange={ ()=> setOnlyDrivein((p)=>!p)   }  value={onlyDrivein}  title="drivein"  />
              <ToggleButton   onChange={ ()=> setOnlyPickup((p)=>!p)   }  value={onlyPickup}  title="pickup"  />
            </div>




            <div className="inline-flex rounded gap-2">
            {
              formasPago.map(i=><ToggleButton   onChange={ ()=> setFiltroFp(
                prev=>{
                  let r=prev.slice();
                  r.includes(i) ? r= r.filter( a=> a!=i) : r.push( i ); 
                  return r; }
              )   }  value={ filtroFp.includes(i) }  title={i}  />)
            }
            </div>

          </div>


      <div className="inline-flex  rounded gap-2">
          {
            sucursales.map( i=>
          <ToggleButton   onChange={ ()=> setFiltroSucursal(
                  prev=>{
                    let r=prev.slice();
                    r.includes(i.nombre) ? r= r.filter( a=> a!=i.nombre) : r.push( i.nombre ); 
                    return r; }
                )   }  value={ filtroSucursal.includes(i.nombre) }  title={i.nombre}  />
            )
          }
      </div>
</div>









<p className="p-5 text-2xl" >  <Moment  format="LL" locale="es">{fecha}</Moment> </p>


<div className="flex gap-8 flex-col pb-16 ">

<TablaPedidos 
data={filtered}
regenerarHandle={ regenerar }
retornoHandle={ solicitarRetorno }
verPedidoHandle={ verPedido }
/>

          <div>
          <p>Integración PB</p>
<TablaPedidosIntegracion
data={filtered}
loading={loading}
integrarPb={integrarPb}

/>
          </div>


</div>




 </div>
      </div>
    )
}
